import React from "react"

import Layout from "../components/layout"

import styles from "../styles/pages/contact-us.module.css"

const ContactPage = () => (
	<Layout activePath='contact-us' pageTitle="Contact Us">
		<div className={styles.maincontent+" text-centered color-background-gray-af"}>
			<div className={styles.contactholder}>
				<h1 className={"color-background-gray-af"}>Contact Us</h1>
				<div className={styles.contactdata+" color-background-primary"}>
					<h2>Goldstar Securities, Inc.</h2>
					<div className={styles.contactaddress+" color-text-primary"}>
						2201 East Tower, PSE Centre, Pasig City, Metro Manila, Philippines 1605
					</div>
					<ul className={"color-text-primary"}>
						<li>(632) 8633 7485</li>
						<li>(632) 8633 7486</li>
						<li>(632) 8636 0197</li>
						<li>(632) 8636 0198</li>
						<li>(632) 8636 0199</li>
					</ul>
				</div>
			</div>
		</div>
		<div className={styles.privacycontent}>
			<h2 className={"font-style-regular  color-text-primary"}>Privacy Policy</h2>
			<div className={"color-text-primary"}>
				The Company shall protect the data that you provide in compliance with the Data Privacy Law of 2012 and its implementing rules and regulations.  We are committed to protecting your privacy as a visitor to this website and as our customer. We are bound by strict rules of confidentiality and, unless we are required to do so by law, rule or regulation, we will not disclose any information you may provide us to any person without your permission. 
			</div>
		</div>
	</Layout>
)

export default ContactPage
